import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "@utils/axios";
import { useUser } from "../providers/userProvider";
import { Badge } from "@radix-ui/themes";

interface ConversationLog {
  sender: string;
  timestamp: number;
  text: string;
}

interface Insights {
  prices: string[];
  quantities: string[];
  keywords: string[];
}

interface FetchResponse {
  completed: boolean;
  logs: ConversationLog[];
  insights: Insights;
}

const CallData = () => {
  const { conversationId, phoneNumber, setPrices, setQuantities, setKeywords } = useUser();
  const [conversationLogs, setConversationLogs] = useState<ConversationLog[]>([]);
  const [callCompleted, setCallCompleted] = useState<boolean>(false);
  const logsContainerRef = useRef<HTMLDivElement>(null); // Create a ref for the logs container

  useEffect(() => {
    // Scroll to bottom function
    const scrollToBottom = () => {
      if (logsContainerRef.current) {
        logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
      }
    };

    // Call scrollToBottom whenever conversationLogs changes
    scrollToBottom();
  }, [conversationLogs]);

  useEffect(() => {
    let intervalId;
    setCallCompleted(false);
    setConversationLogs([]);

    const fetchData = async () => {
      try {
        console.log("fetch");
        const response = await axiosInstance.get<FetchResponse>(`/conversation_logs/${conversationId}`);
        console.log("Data fetched:", response.data);
        const { completed, logs, insights } = response.data;
        setCallCompleted(completed);
        // Transform 'sender' from 'human/bot' to 'customer/agent'
        const transformedLogs = logs.map((log) => ({
          ...log,
          sender: log.sender === "human" ? "customer" : "agent",
        }));
        setConversationLogs(transformedLogs);
        if (completed) {
          if (insights) {
            setPrices(insights.prices);
            setQuantities(insights.quantities);
            setKeywords(insights.keywords);
          }
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
          return;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    console.log(conversationId, callCompleted);
    if (conversationId) {
      intervalId = setInterval(fetchData, 1000);
      //setIntervalId(interval);
      console.log("interval");

      return () => clearInterval(intervalId);
    }
  }, [conversationId]);

  const formatTimestamp = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  return (
    <div className="w-full">
      <h1 className="mb-6 flex items-center gap-4 font-serif text-2xl">
        Call Logs{" "}
        {callCompleted ? (
          <Badge color="green">Call Completed</Badge>
        ) : conversationLogs.length > 0 ? (
          <Badge color="orange">Call in Progress</Badge>
        ) : (
          <Badge color="gray">Not Started</Badge>
        )}
      </h1>{" "}
      {/* Makes the text bigger */}
      <div
        ref={logsContainerRef}
        className={`${conversationLogs.length === 0 ? "bg-ruby1" : "bg-ruby2"} min-w-96 flex h-72 w-full flex-col gap-4 overflow-y-auto rounded-lg p-4`}
      >
        {conversationLogs.map((log, index) => (
          <div key={index} className={`log ${log.sender}`}>
            {log.text.length > 0 && (
              <p className="break-words">
                <span className="text-ruby10 font-bold">{`${log.sender.charAt(0).toUpperCase() + log.sender.slice(1)}`}</span>
                : {log.text}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CallData;
