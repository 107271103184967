import React, { useState } from "react";
import * as Form from "@radix-ui/react-form";
import { useUser } from "@providers/userProvider";
import axiosInstance from "@utils/axios";

export default function CallForm() {
  const {
    setConversationId,
    setPhoneNumber: setUserPhoneNumber,
    setName: setUserName,
    setPrices,
    setKeywords,
    setQuantities,
  } = useUser();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");

  const validatePhoneNumber = (number: string) => {
    const regex = /^\+?[1-9]\d{1,14}$/;
    return regex.test(number);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (!value.startsWith("+1") && value !== "" && value !== "+") {
      value = `+1${value}`;
    } else if (value === "+") {
      value = "";
    }
    setPhoneNumber(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form from submitting
    const isValid = validatePhoneNumber(phoneNumber);
    setIsValidPhoneNumber(isValid);

    if (isValid) {
      console.log("Phone number is valid:", phoneNumber);

      try {
        const response = await axiosInstance.post("/start_outbound_call", {
          phone: phoneNumber,
          name: name,
          company: company,
        });
        console.log(response.data);
        setConversationId(response.data.conversationId);
        setUserPhoneNumber(phoneNumber);
        setUserName(name);
        setPrices([]);
        setKeywords([]);
        setQuantities([]);
      } catch (error) {
        console.error("Error initiating call", error);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <h1 className="mb-2 font-serif text-2xl">Price Discovery Dialer</h1> {/* Makes the text bigger */}
      <Form.Root className="w-[260px]" onSubmit={handleSubmit}>
        <Form.Field className="mb-[10px]" name="name">
          <Form.Label>Name</Form.Label>
          <Form.Control asChild>
            <input
              className="focus:border-ruby11 box-border inline-flex h-[35px] w-full appearance-none items-center justify-center rounded-[4px] border-[1px] border-black px-[10px] text-[15px] leading-none outline-none focus:border-2"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field className="mb-[10px]" name="company">
          <Form.Label>Company</Form.Label>
          <Form.Control asChild>
            <input
              className="focus:border-ruby11 box-border inline-flex h-[35px] w-full appearance-none items-center justify-center rounded-[4px] border-[1px] border-black px-[10px] text-[15px] leading-none outline-none focus:border-2"
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field className="mb-[10px] grid" name="phone">
          <div className="flex flex-col items-baseline justify-between">
            <Form.Label>Phone number</Form.Label>
            {!isValidPhoneNumber && phoneNumber && (
              <Form.Message className="text-[13px] text-black opacity-[0.8]">
                Please provide a valid phone number
              </Form.Message>
            )}
          </div>
          <Form.Control asChild>
            <input
              className="focus:border-ruby11 box-border inline-flex h-[35px] w-full appearance-none items-center justify-center rounded-[4px] border-[1px] border-black px-[10px] text-[15px] leading-none outline-none focus:border-2"
              type="tel"
              required
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={12}
              pattern="\+1\d{10}"
            />
          </Form.Control>
        </Form.Field>
        <Form.Submit asChild>
          <button
            type="submit"
            className="bg-ruby10 hover:bg-ruby11 mt-[10px] box-border inline-flex h-[35px] w-full items-center justify-center rounded-[4px] px-[15px] font-medium leading-none text-white focus:outline-none"
          >
            Call Number
          </button>
        </Form.Submit>
      </Form.Root>
    </div>
  );
}
