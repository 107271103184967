import React, { useEffect, useState } from "react";
import axiosInstance from "@utils/axios";
import { useUser } from "../providers/userProvider";
import { Badge, Code, DataList, Flex, IconButton, Link } from "@radix-ui/themes";
import { CopyIcon } from "@radix-ui/react-icons";
import { blackA, violet, mauve, grass, ruby } from "@radix-ui/colors";
const Insights = () => {
  const { name, phoneNumber, prices, quantities, keywords } = useUser();

  return (
    <div className="w-full">
      <h1 className="mb-6 flex items-center gap-4 font-serif text-2xl">Insights</h1> {/* Makes the text bigger */}
      <div className="min-w-96 flex h-72 w-full flex-col gap-4 overflow-y-auto rounded-lg">
        <DataList.Root>
          <DataList.Item>
            <DataList.Label minWidth="88px" color="ruby" className="font-bold">
              Name
            </DataList.Label>
            <DataList.Value>{name}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px" color="ruby" className="font-bold">
              Phone Number
            </DataList.Label>
            <DataList.Value>{phoneNumber}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px" color="ruby" className="font-bold">
              Prices
            </DataList.Label>
            <DataList.Value>{prices.join(", ")}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px" color="ruby" className="font-bold">
              Quantity
            </DataList.Label>
            <DataList.Value>{quantities.join(", ")}</DataList.Value>
          </DataList.Item>
          {/* <DataList.Item>
            <DataList.Label minWidth="88px" color="ruby" className="font-bold">
              Keywords
            </DataList.Label>
            <DataList.Value>{keywords.join(", ")}</DataList.Value>
          </DataList.Item> */}
        </DataList.Root>
      </div>
    </div>
  );
};

export default Insights;
