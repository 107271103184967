import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserContextType {
  conversationId: string;
  setConversationId: (id: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
  name: string;
  setName: (name: string) => void;
  prices: string[];
  setPrices: (prices: string[]) => void;
  quantities: string[];
  setQuantities: (quantities: string[]) => void;
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [conversationId, setConversationId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [prices, setPrices] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);

  return (
    <UserContext.Provider
      value={{
        conversationId,
        setConversationId,
        phoneNumber,
        setPhoneNumber,
        name,
        setName,
        prices,
        setPrices,
        quantities,
        setQuantities,
        keywords,
        setKeywords,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
