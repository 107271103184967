import { useState } from "react";
import { To, useNavigate } from "react-router-dom";
import { Button, Link } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";

const Nav = () => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleNavigate = (path: To) => {
    // Close mobile menu when navigating
    setShowMobileMenu(false);
    navigate(path);
  };

  const MobileNav = () => (
    <div className="mobile-nav bg-ruby10 relative md:hidden">
      <div className="ml-auto flex justify-end">
        <Button className="bg-ruby10 border-none" onClick={() => setShowMobileMenu(!showMobileMenu)}>
          {showMobileMenu ? <Cross2Icon style={{ color: "white" }} /> : "Menu"}
        </Button>
      </div>
      {showMobileMenu && (
        <div className="mobile-menu bg-ruby10 absolute top-full right-10 z-10 p-8">
          <div className="flex flex-col pt-1 pb-5" style={{ gap: "16px" }}>
            <h2 className="m-auto mb-1">Menu</h2>
            <Button
              style={{
                width: "80%",
                textAlign: "left",
                color: "black",
                padding: "8px 16px",
                backgroundColor: "white",
                border: "none",
                margin: "auto",
              }}
              onClick={() => handleNavigate("/your-jobs")}
            >
              Your Jobs
            </Button>
            <Button
              style={{
                width: "80%",
                textAlign: "left",
                color: "black",
                padding: "8px 16px",
                backgroundColor: "white",
                border: "none",
                margin: "auto",
              }}
              onClick={() => handleNavigate("/active-jobs")}
            >
              Active Jobs
            </Button>
            <Button
              style={{
                width: "80%",
                textAlign: "left",
                color: "black",
                padding: "8px 16px",
                backgroundColor: "white",
                border: "none",
                margin: "auto",
              }}
              onClick={() => handleNavigate("/completed-jobs")}
            >
              Completed Jobs
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  const DesktopNav = () => (
    <nav className="desktop-nav to-ruby11 from-ruby10 hidden h-screen w-1/6 flex-col border-r border-white-light bg-gradient-to-r text-white md:flex">
      <div className="w-full border-b border-white-light p-6">
        <>
          <div className="font-serif text-2xl">Tiffany</div>
          <div className="text-md text-left font-bold">Lakeside Farms</div>
        </>
      </div>
      <div className="flex h-full flex-col justify-between p-6">
        <div className="flex flex-col justify-start gap-y-6">
          <>
            <button className="text-left font-bold" onClick={() => handleNavigate("/marketplace")}>
              Contacts
            </button>
            <button className="text-left font-bold" onClick={() => handleNavigate("/training")}>
              Price Discovery
            </button>
            <button className="text-left font-bold" onClick={() => handleNavigate("/stats")}>
              Insights
            </button>
            <button className="text-left font-bold" onClick={() => handleNavigate("/stats")}>
              Inventory
            </button>
          </>
        </div>
        <div className="flex flex-col justify-start gap-y-6">
          <button className="text-left font-bold" onClick={() => handleNavigate("/account")}>
            Your Account
          </button>
          <button className="text-left font-bold" onClick={() => handleNavigate("/settings")}>
            Settings
          </button>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      <MobileNav />
      <DesktopNav />
    </>
  );
};

export default Nav;
