import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@radix-ui/themes/styles.css";

import { Theme } from "@radix-ui/themes";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "@pages/home";
import { UserProvider } from "@providers/userProvider";
import Nav from "@components/nav";
// Monitoring
// if (import.meta.env.PROD) {
//   Sentry.init({
//     dsn,
//     integrations: [
//       new CaptureConsole({
//         levels: ["error"],
//       }),
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: [
//           // "localhost",
//         ],
//         // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         //   React.useEffect,
//         //   useLocation,
//         //   useNavigationType,
//         //   createRoutesFromChildren,
//         //   matchRoutes
//         // ),
//       }),
//       new Sentry.Replay({
//         maskAllText: false,
//         blockAllMedia: false,
//         maskAllInputs: false,
//         networkDetailAllowUrls: [
//           window.location.origin,
//         ],
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

const MainRoutes = () => {
  return (
    <div className="flex h-screen w-full flex-col overflow-auto md:flex-row">
      {" "}
      <Nav />
      <Routes>
        <Route path="/" Component={Home} />
      </Routes>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Theme>
      <BrowserRouter>
        <UserProvider>
          <MainRoutes />
        </UserProvider>
      </BrowserRouter>
    </Theme>
  </React.StrictMode>
);
