import CallData from "@components/callData";
import CallForm from "@components/callForm";
import Insights from "@components/insights";

const Home = () => {
  return (
    <div className="bg-ruby1 flex h-full w-full flex-col gap-4">
      <div className="m-4">
        <div className="mx-2 mt-2">
          <div className="flex flex-row gap-16">
            <CallForm />
            <CallData />
          </div>
          <div className="mt-8">
            <Insights />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
